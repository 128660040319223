import React from 'react';
import { LogoLink } from '../components/logo/LogoLink';
import { Content } from '../components/content/Content';
import { Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DisplacementSphere from '../components/background/DisplacementSphere';
import { ThemeToggle } from '../components/theme/ThemeToggle';
import { Resume } from '../components/resume/Resume';
import { SocialIcons } from '../components/content/SocialIcons';
import { SpeedDials } from '../components/speedDial/SpeedDial';
import { SideNavbar } from '../components/nav/SideNavbar';
import { Works } from '../components/works/Works';
import { About } from '../components/about/About';
import { Contact } from '../components/contact/Contact';
import background1 from '../assets/backgroung1.jpg'
import { TextDecrypt } from "../components/content/TextDecrypt";
import { Container } from "@material-ui/core";
const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
}));


export const PP = () => {
  const classes = useStyles();

  return (
    <>




      <SideNavbar />
      {/* <Works />
        <About />
        <Contact /> */}
      <div
        style={{
          display: 'flex',
          // background:'red',
          flex: 1,
          height: '100%',
          marginLeft: 100,
          padding: 100
        }}>

        <div
          className="__content_wrapper"
          style={{ display: 'flex', flex: 1, padding: 100 }}
        >
          <h2 className="title">
            <TextDecrypt text={'Privacy Policy'} />
          </h2>
          <p >
            Last Update Posted: May 4, 2023
          </p>
          <p style={{ textAlign: 'left' }}>
            This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.

            We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the Free Privacy Policy Generator.
          </p>


          <div style={{ textAlign: 'left' }}>
            {tosDescription.map((e, i) => {
              return <>
                {e?.header &&
                  <h2 className="title">
                    <TextDecrypt text={e?.header} />
                  </h2>}
                <strong >
                  {e.title}
                </strong>
                <p >
                  {e.description}
                </p>
                {e.child ?
                  e.child.map((ee, ii) => {
                    return <div style={{ display: 'flex', marginLeft: 50, flexDirection: 'column' }}>
                      <strong >
                        {ee.title}
                      </strong>
                      <p >
                        {ee.description}
                      </p>
                    </div>
                  })
                  :
                  null
                }
              </>
            })}
          </div>
        </div>

      </div>

    </>
  );
};

const tosDescription = [
  {
    id: 1,
    header: 'Interpretation and Definitions',
    title: 'Interpretation',
    description: `The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.`
  },
  {
    id: 2,
    title: 'Definitions',
    description: `For the purposes of this Privacy Policy:`,
    child:[
      {
        id:'2.1',
        title:'Account ',
        description:`means a unique account created for You to access our Service or parts of our Service`
       },
       {
        id:'2.2',
        title:'Affiliate  ',
        description:`means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.`
       },
       {
        id:'2.3',
        title:'Application  ',
        description:`means the software program provided by the Company downloaded by You on any electronic device, named Omega.`
       },
       {
        id:'2.4',
        title:'Company  ',
        description:`(referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Omega tech.`
       },
       {
        id:'2.5',
        title:'Device  ',
        description:`means any device that can access the Service such as a computer, a cellphone or a digital tablet.`
       },
       {
        id:'2.6',
        title:'Personal Data ',
        description:`is any information that relates to an identified or identifiable individual.`
       },
       {
        id:'2.7',
        title:'Service  ',
        description:`is any information that relates to an identified or identifiable individual.`
       },
       {
        id:'2.8',
        title:'Service Provider ',
        description:`means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.`
       },
       {
        id:'2.9',
        title:'Third-party Social Media Service ',
        description:`refers to any website or any social network website through which a User can log in or create an account to use the Service.`
       },
       {
        id:'2.10',
        title:'Usage Data ',
        description:`refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).`
       },
       {
        id:'2.11',
        title:'You  ',
        description:`means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.`
       },
    ]
  },
  {
    id: 3,
    header:'Collecting and Using Your Personal Data',
    title: 'Types of Data Collected',
    description: ``
  },
  {
    id: 4,
    title: 'Personal Data',
    description: `While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:`,
    child:[{
      id:'4.1',
      title:'',
      description:'First name and last name'
    },{
      id:'4.2',
      title:'',
      description:'Phone number'
    },{
      id:'4.3',
      title:'',
      description:'Usage Data'
    },
  ]
  },
  {
    id:'5',
    title:'Usage data',
    description:`Usage Data is collected automatically when using the Service.

    Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
    
    When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.
    
    We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.`
  },{
    id:6,
    title:'Information from Third-Party Social Media Services',
    description:`The Company allows You to create an account and log in to use the Service through the following Third-party Social Media Services:

    Google,
    Facebook,
    twitter,
    Linkedin. 
    If You decide to register through or otherwise grant us access to a Third-Party Social Media Service, We may collect Personal data that is already associated with Your Third-Party Social Media Service's account, such as Your name, Your email address, Your activities or Your contact list associated with that account.
    
    You may also have the option of sharing additional information with the Company through Your Third-Party Social Media Service's account. If You choose to provide such information and Personal Data, during registration or otherwise, You are giving the Company permission to use, share, and store it in a manner consistent with this Privacy Policy.`
  },
  {
    id:7,
    title:'Information Collected while Using the Application',
    description:`While using Our Application, in order to provide features of Our Application, We may collect, with Your prior permission:

    Pictures and other information from your Device's camera and photo library
    We use this information to provide features of Our Service, to improve and customize Our Service. The information may be uploaded to the Company's servers and/or a Service Provider's server or it may be simply stored on Your device.
    
    You can enable or disable access to this information at any time, through Your Device settings.`
  },
  {
    id:8,
    title:'Use of Your Personal Data',
    description:`The Company may use Personal Data for the following purposes:`
  },
  {
    id:9,
    title:'Retention of Your Personal Data',
    description:`The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.

    The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.`
  },
  {
    id:10,
    title:'Transfer of Your Personal Data',
    description:`Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.

    Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.
    
    The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.`
  },
  {
    id:11,
    title:'Delete Your Personal Data',
    description:`You have the right to delete or request that We assist in deleting the Personal Data that We have collected about You.

    Our Service may give You the ability to delete certain information about You from within the Service.
    
    You may update, amend, or delete Your information at any time by signing in to Your Account, if you have one, and visiting the account settings section that allows you to manage Your personal information. You may also contact Us to request access to, correct, or delete any personal information that You have provided to Us.
    
    Please note, however, that We may need to retain certain information when we have a legal obligation or lawful basis to do so.`
  },
  {
    id:12,
    title:'Disclosure of Your Personal Data',
    description:``
  },
  ,
  {
    id:13,
    title:'Security of Your Personal Data',
    description:`The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.`
  },
  ,
  {
    id:14,
    title:`Children's Privacy`,
    description:`Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.

    If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.`
  },
  {
    id:15,
    title:`Links to Other Websites`,
    description:`Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.

    We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.`
  },
  {
    id:16,
    title:`Changes to this Privacy Policy`,
    description:`We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.

    We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.
    
    You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.`
  },
  {
    id:17,
    title:`Contact Us`,
    description:`If you have any questions about this Privacy Policy, You can contact us: hello@iicoteck.com`
  }
]