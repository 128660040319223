import React from 'react';
import { LogoLink } from '../components/logo/LogoLink';
import { Content } from '../components/content/Content';
import { Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DisplacementSphere from '../components/background/DisplacementSphere';
import { ThemeToggle } from '../components/theme/ThemeToggle';
import { Resume } from '../components/resume/Resume';
import { SocialIcons } from '../components/content/SocialIcons';
import { SpeedDials } from '../components/speedDial/SpeedDial';
import { SideNavbar } from '../components/nav/SideNavbar';
import { Works } from '../components/works/Works';
import { About } from '../components/about/About';
import { Contact } from '../components/contact/Contact';
import background1 from '../assets/backgroung1.jpg'
import { TextDecrypt } from "../components/content/TextDecrypt";
import { Container } from "@material-ui/core";
const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
}));


export const ToS = () => {
  const classes = useStyles();

  return (
    <>




      <SideNavbar />
      {/* <Works />
        <About />
        <Contact /> */}
      <div
        style={{
          display: 'flex',
          // background:'red',
          flex: 1,
          height: '100%',
          marginLeft: 100,
          padding: 100
        }}>

        <div
          className="__content_wrapper"
          style={{ display: 'flex', flex: 1, padding: 100 }}
        >
          <h2 className="title">
            <TextDecrypt text={'Terms of Service'} />
          </h2>
          <p className="title">
            <TextDecrypt text={'Last Update Posted: May 4, 2023'} />
          </p>
          <p className="title" style={{ textAlign: 'center' }}>
            <TextDecrypt text={'IMPORTANT NOTICE: THIS AGREEMENT IS SUBJECT TO BINDING ARBITRATION AND A WAIVER OF CLASS ACTION RIGHTS AS DETAILED IN SECTION 15. PLEASE READ THE AGREEMENT CAREFULLY.'} />
          </p>
          <div style={{ textAlign: 'left' }}>
            {tosDescription.map((e, i) => {
              return <>
                <strong >
                  {e.id + '. '}{e.title}
                </strong>
                <p >
                  {e.description}
                </p>
                {e.child ?
                  e.child.map((ee, ii) => {
                    return <div style={{ display: 'flex', marginLeft: 50, flexDirection: 'column' }}>
                      <strong >
                        {ee.id + '. '}{ee.title}
                      </strong>
                      <p >
                        {ee.description}
                      </p>
                    </div>
                  })
                  :
                  null
                }
              </>
            })}
          </div>
        </div>

      </div>

    </>
  );
};

const tosDescription = [
  {
    id: 1,
    title: 'Acceptance of Terms',
    description: `IICO TECK, LTG., its parent and affiliates ("IICO TECK") provides a platform for managing crypto currency and crypto assets, for mining the cryptocurrency OM, for enabling individual users to contribute to the security and community of iiCO Network, and for allowing ordinary websites and applications to interact with blockchains to build a blockchain ecosystem.
  
    These Terms of Service (these "Terms" or "Terms of Service") are entered into by and between you and IICO TECK. and govern your access to and use of the IICO TECK website located at https://iicoteck.com and at https://iicoteck.com, our mobile apps, a desktop application called OM Node, our browser plugin and our social media sites (collectively, the "Site"), and includes all text, images, audio, code and other materials and all of the features, and services provided (collectively, the "Content"). The Site, and any other features, tools, materials, or other services offered from time to time by IICO TECK are referred to here as the "Service."
    
    Please read these Terms of Service carefully before using the Service. By using or otherwise accessing the Services, or clicking to accept or agree to these Terms where that option is made available, you (1) accept and agree to these Terms (2) consent to the collection, use, disclosure and other handling of information as described in our Privacy Policy located at https://iicoteck.com/privacy-policy and (3) agree to and accept any additional terms, rules and conditions of participation issued by IICO TECK from time to time. If you do not agree to the Terms, then you may not access or use the Content or Services.`
  },
  {
    id: 2,
    title: 'Modification of Terms of Service',
    description: `Except for Section 15, providing for binding arbitration and waiver of class action rights, IICO TECK reserves the right, at its sole discretion, to modify or replace the Terms of Service at any time. The most current version of these Terms will be posted on our Site. You are responsible for reviewing and becoming familiar with any such modifications. Use of the Services by you after any modification to the Terms constitutes your acceptance of the Terms of Use as modified.`
  },
  {
    id: 3,
    title: 'Eligibility',
    description: `You hereby represent and warrant that you are fully able and competent to enter into the terms, conditions, obligations, affirmations, representations and warranties set forth in these Terms; that you are using the Site solely for your personal use; and that you will abide by and comply with these Terms. This Site is not directed to users under the age of 13.

    IICO TECK is a global platform and by accessing the Content or Services, you are representing and warranting that, you are of the legal age of majority in your jurisdiction as is required to access such Services and Content and enter into arrangements as provided by the Service. You further represent that you are otherwise legally permitted to use the Service in your jurisdiction, including owning cryptographic tokens of value, and interacting with the Services or Content in any way. You further represent you are responsible for ensuring compliance with the laws of your jurisdiction and acknowledge that IICO TECK is not liable for your compliance with such laws.`
  },
  {
    id: 4,
    title: 'Account Password and Security',
    description: `When setting up an account within IICO TECK, you will be responsible for keeOMng your passwords, which you use to sign into your OM account, and which we use to keep third-party accounts safe. IICO TECK encrypts this information locally with a random keyword we provide and securely store on our servers for your convenience. By using the Service, You agree that (a) all registration information you provide to us is accurate, complete and correct; (b) all registration information you provide is governed by the terms of our Privacy Policy at https://iicoteck.com/privacy-policy (c) you will have two-way authentication login for any third-party service used to access the Service, e.g. Facebook; (d) you will never use the same password for the IICO TECK-connected Facebook account for any account or purpose outside of this Service; (e) you will keep your password to the third-party services (Facebook) confidential and not share them with anyone else; (f) you will immediately notify IICO TECK of any unauthorized use of your account or breach of security in the third-party services (Facebook). IICO TECK cannot and will not be liable for any loss or damage arising from your failure to comply with this section.`
  },
  {
    id: 5,
    title: 'Submissions',
    description: `If you post material to the Site, or otherwise make (or allow any third party to make) material available by means of the Site (any such material, “User Content”), you are entirely responsible for the content of, and any harm resulting from, such User Content. That is the case regardless of whether your User Content constitutes text, graphics, an audio file, or computer software.

    By making User Content available, you represent and warrant that:
    
    Your User Content does not contain or install any viruses, worms, malware, Trojan horses or other harmful or destructive content;
    Your User Content is not spam and does not contain unethical or unwanted commercial content designed to drive traffic to third party sites or boost the search engine rankings of third party sites, or to further unlawful acts (such as phishing) or mislead recipients as to the source of the material (such as spoofing);
    You will post only User Content that is relevant to IICO TECK and iiCO Network and at a rate and volume that does not hinder other Users' ability to use our platform;
    Your User Content is not libelous or defamatory, does not contain threats or incite violence towards individuals or entities, and does not violate the privacy or publicity rights of any third party.
    By submitting User Content to IICO TECK for inclusion on the Site, you grant IICO TECK a world-wide, perpetual, royalty-free, and non-exclusive license to reproduce, modify, adapt, and publish your User Content in connection with the Site. You represent and warrant that (a) you own and control all of the rights to the User Content that you post or you otherwise have the right to post to the Site; (b) your User Content is accurate and not misleading; and (c) use and posting of your Content does not violate these Terms of Service and will not violate any rights of or cause injury to any person or entity.
    
    Without limiting any of these representations or warranties, IICO TECK has the right (though not the obligation), in IICO TECK's sole discretion, to terminate or deny access to and use of the Site to any individual or entity for any reason.`
  }
  ,
  {
    id: 6,
    title: 'Acceptable Use',
    description: `Except as expressly provided in these Terms, you agree that you will not: (i) copy the Services or Content or any portion thereof; (ii) distribute, modify, adapt, translate, reverse engineer, decompile, disassemble, create derivative works based on, print any part of, or otherwise attempt to discover the source code, compiled executables, intermediate files, or underlying structure, ideas, know-how or algorithms relating in any way to the Services or Content; (iii) use the Services to send or store infringing, obscene, threatening, libelous, or otherwise unlawful material, including material that violates third party rights; (vi) use the Services to create, use, send, store, or run material containing software viruses, worms, Trojan horses or otherwise engage in any malicious act or disrupt the security, integrity or operation of the Services; (vii) use the Services in any manner that violates any applicable foreign, federal, state or local law, rule or regulation; (viii) use the Services in any manner that violates any third party rights or the terms of this Agreement; (ix) use scripting, computer programs or third-party scripting services to create fake accounts, to receive OM rewards intended for accounts held by real human beings, or spam the chat rooms on the Site; (x) sell or transfer user accounts to another individual or entity; or (xi) participate in the sale of OM unauthorized by IICO TECK or its affiliates. The violation of these terms may result in IICO TECK, in IICO TECK's sole discretion, terminating or denying access to and use of the Site, freezing accounts, removing or canceling previously awarded OM of any individual or entity.`
  }
  ,
  {
    id: 7,
    title: 'Representations, Warranties and Risks',
    description: '',
    child: [
      {
        id: '7.1',
        title: 'Warranty Disclaimer',
        description: 'You expressly understand and agree that your use of the Service is at your sole risk. The Service and the Content are provided on an "AS IS" and "as available" basis, without warranties of any kind, either express or implied, including, without limitation, implied warranties of merchantability, fitness for a particular purpose or non-infringement. You acknowledge that IICO TECK has no control over, and no duty to take any action regarding: which users gain access to or use the Service; what effects the Content may have on you; how you may interpret or use the Content; or what actions you may take as a result of having been exposed to the Content. You release IICO TECK from all liability arising from your access to or use of the Service or the Content. IICO TECK makes no representations concerning any Content contained in or accessed through the Service, and IICO TECK will not be responsible or liable for the accuracy, copyright compliance, legality or decency of material contained in or accessed through the Service.'
      },
      {
        id: '7.2',
        title: 'Sophistication and Risk of Cryptographic Systems',
        description: 'By utilizing the Service or interacting with the Content or platform in any way, you represent that you understand the inherent risks associated with cryptographic systems; and warrant that you have an understanding of the usage and intricacies of native cryptographic tokens, like Ether (ETH) and Bitcoin (BTC), smart contract based tokens such as those that follow the Ethereum Token Standard (https://eips.ethereum.org/EIPS/eip-20), and blockchain-based software systems.'
      },
      {
        id: '7.3',
        title: ' Risk of Regulatory Actions in One or More Jurisdictions',
        description: 'IICO TECK and the crypto assets you store on IICO TECK could be impacted by one or more regulatory inquiries or regulatory action, which could impede or limit the ability of IICO TECK to continue to develop, or which could impede or limit your ability to access or use, the Service.'
      },
      {
        id: '7.4',
        title: 'Risk of Weaknesses or Exploits in the Field of Cryptography',
        description: 'You acknowledge and understand that Cryptography is a progressing field. Advances in code cracking or technical advances such as the development of quantum computers may present risks to cryptocurrencies, the Services or the Content, which could result in the theft or loss of your cryptographic tokens or property. To the extent possible, IICO TECK intends to update the protocol underlying Services to account for any advances in cryptography and to incorporate additional security measures. IICO TECK does not guarantee or otherwise represent full security of the system. By using the Service or accessing Content, you acknowledge these inherent risks.'
      },
      {
        id: '7.5',
        title: 'Volatility of Crypto Currencies',
        description: 'You understand that Ethereum and other blockchain technologies and associated currencies or tokens are highly volatile due to many factors including but not limited to adoption, speculation, technology and security risks. You also acknowledge that after the Mainnet of OM becomes live or OM is listed in exchanges, OM will be of similar volatility and subject to the same factors faced by other cryptocurrencies. You also acknowledge that the cost of transacting on such technologies is variable and may increase at any time causing impact to any activities taking place on the blockchains. You acknowledge these risks and represent that IICO TECK cannot be held liable for such fluctuations or increased costs.'
      },
      {
        id: '7.6',
        title: 'Application Security',
        description: 'You acknowledge that blockchain applications are code subject to flaws and acknowledge that you are solely responsible for evaluating any code provided by the Services or Content and the trustworthiness of any third-party websites, products, smart-contracts, or Content you access or use through the Service. You further expressly acknowledge and represent that blockchain applications can be written maliciously or negligently, that IICO TECK cannot be held liable for your interaction with such applications and that such applications may cause the loss of property or even identity. This warning and others later provided by IICO TECK in no way evidence or represent an on-going duty to alert you to all of the potential risks of utilizing the Service or Content.'
      }
    ]
  },
  {
    id: 8,
    title: 'Indemnity',
    description: `You agree to release and to indemnify, defend and hold harmless IICO TECK and its parents, subsidiaries, affiliates and agencies, as well as the officers, directors, employees, shareholders and representatives of any of the foregoing entities, from and against any and all losses, liabilities, expenses, damages, costs (including attorneys' fees and court costs), claims or actions of any kind whatsoever arising or resulting from your use of the Service other than as expressly authorized by and pursuant to the terms of these Terms of Service, your violation of these Terms of Service, and any of your acts or omissions that implicate publicity rights, defamation or invasion of privacy. IICO TECK reserves the right, at its own expense, to assume exclusive defense and control of any matter otherwise subject to indemnification by you and, in such case, you agree to cooperate with IICO TECK in the defense of such matter.`
  },
  {
    id: 9,
    title: 'Limitation of Liability',
    description: `YOU ACKNOWLEDGE AND AGREE THAT YOU ASSUME FULL RESPONSIBILITY FOR YOUR USE OF THE SITE AND SERVICE. YOU ACKNOWLEDGE AND AGREE THAT ANY INFORMATION YOU SEND OR RECEIVE DURING YOUR USE OF THE SITE AND SERVICE MAY NOT BE SECURE AND MAY BE INTERCEPTED OR LATER ACQUIRED BY UNAUTHORIZED PARTIES. YOU ACKNOWLEDGE AND AGREE THAT YOUR USE OF THE SITE AND SERVICE IS AT YOUR OWN RISK. RECOGNIZING SUCH, YOU UNDERSTAND AND AGREE THAT, TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, NEITHER IICO TECK NOR ITS SUPPLIERS OR LICENSORS WILL BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE, EXEMPLARY OR OTHER DAMAGES OF ANY KIND, INCLUDING WITHOUT LIMITATION DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER TANGIBLE OR INTANGIBLE LOSSES OR ANY OTHER DAMAGES BASED ON CONTRACT, TORT, STRICT LIABILITY OR ANY OTHER THEORY (EVEN IF IICO TECK HAD BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM THE SITE OR SERVICE; THE USE OR THE INABILITY TO USE THE SITE OR SERVICE; UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SITE OR SERVICE; ANY ACTIONS WE TAKE OR FAIL TO TAKE AS A RESULT OF COMMUNICATIONS YOU SEND TO US; HUMAN ERRORS; TECHNICAL MALFUNCTIONS; FAILURES, INCLUDING PUBLIC UTILITY OR TELEPHONE OUTAGES; OMISSIONS, INTERRUPTIONS, LATENCY, DELETIONS OR DEFECTS OF ANY DEVICE OR NETWORK, PROVIDERS, OR SOFTWARE (INCLUDING, BUT NOT LIMITED TO, THOSE THAT DO NOT PERMIT PARTICIPATION IN THE SERVICE); ANY INJURY OR DAMAGE TO COMPUTER EQUIPMENT; INABILITY TO FULLY ACCESS THE SITE OR SERVICE OR ANY OTHER WEBSITE; THEFT, TAMPERING, DESTRUCTION, OR UNAUTHORIZED ACCESS TO, IMAGES OR OTHER CONTENT OF ANY KIND; DATA THAT IS PROCESSED LATE OR INCORRECTLY OR IS INCOMPLETE OR LOST; TYPOGRAPHICAL, PRINTING OR OTHER ERRORS, OR ANY COMBINATION THEREOF; OR ANY OTHER MATTER RELATING TO THE SITE OR SERVICE.

    SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
    
    IICO TECK assumes no responsibility, and shall not be liable for, any damages to, or viruses that may infect, your computer, telecommunication equipment, or other property caused by or arising from your access to, use of, or browsing the Site or your downloading of any materials, from the Site.`
  },
  {
    id: 10,
    title: 'Our Proprietary Rights',
    description: `The Site, the Service, all functionality of the Site and Service, and all Content and all intellectual property are owned by IICO TECK or its licensors. You acknowledge and agree that the Service contains proprietary and confidential information that is protected by applicable intellectual property and other laws. Except as expressly authorized by IICO TECK, you agree not to copy, modify, rent, lease, loan, sell, distribute, perform, display or create derivative works based on the Service or Content, in whole or in part.
    The trademarks IICO TECK, OM, iiCO Network and the OM Design (the "Trademarks) are trademarks of IICO TECK and its parent, OM Community Company. You may not use the trademarks without the prior written permission of IICO TECK. Any permitted use of the Trademarks must be pursuant to and in compliance with the OM Trademark Guidelines, located at iicoteck.com.`
  },
  {
    id: 11,
    title: 'Links',
    description: `The Service provides, or third parties may provide, links to other World Wide Web or accessible sites, applications or resources. Because IICO TECK has no control over such sites, applications and resources, you acknowledge and agree that IICO TECK is not responsible for the availability of such external sites, applications or resources, and does not endorse and is not responsible or liable for any content, advertising, products or other materials on or available from such sites or resources. You further acknowledge and agree that IICO TECK shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such site or resource.`
  },
  {
    id: 12,
    title: 'Termination and Suspension',
    description: `IICO TECK, in IICO TECK's sole discretion, may terminate or suspend all or part of the Service and your IICO TECK access immediately, without prior notice or liability, if you breach any of the terms or conditions of the Terms. Upon termination of your access, your right to use the Service will immediately cease.

    If you have passed the OM KYC and received the OM cryptocurrency in your Mainnet wallet on the OM Mainnet Blockchain (Migrated OM), in the event that you request for account deletion and termination of services, you will be required to return all your Migrated OM and destroy your OM Mainnet wallet before your OM user account can be deleted. All Migrated OM must be verified through KYC and linked to your account and KYC information as required by law.
    
    The following provisions of the Terms survive any termination of these Terms:
    INDEMNITY; WARRANTY DISCLAIMERS; LIMITATION OF LIABILITY; OUR PROPRIETARY RIGHTS; LINKS; TERMINATION; NO THIRD PARTY BENEFICIARIES; BINDING ARBITRATION AND CLASS ACTION WAIVER; GENERAL INFORMATION.`
  },
  {
    id: 13,
    title: 'No Third Party Beneficiaries',
    description: `You agree that, except as otherwise expressly provided in these Terms, there shall be no third party beneficiaries to the Terms.`
  },
  {
    id: 14,
    title: 'Notice and Procedure For Making Claims of Copyright Infringement',
    description: `If you believe that your copyright or the copyright of a person on whose behalf you are authorized to act has been infringed on the Site, please provide IICO TECK's Copyright Agent a written Notice containing the following information:

    an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright or other intellectual property interest;
    a description of the copyrighted work or other intellectual property that you claim has been infringed;
    a description of where the material that you claim is infringing is located on the Service;
    your address, telephone number, and email address;
    a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;
    a statement by you, made under penalty of perjury, that the above information in your Notice is accurate and that you are the copyright or intellectual property owner or authorized to act on the copyright or intellectual property owner's behalf.
    IICO TECK's Copyright Agent is:
    OM Tech Fan
    cs@iicoteck.com`
  },
  {
    id: 15,
    title: 'Binding Arbitration and Class Action Waiver',
    description: `PLEASE READ THIS SECTION CAREFULLY – IT MAY SIGNIFICANTLY AFFECT YOUR LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT`,
    child: [
      {
        id: '15.1',
        title: 'Initial Dispute Resolution',
        description: `The parties shall use their best efforts to engage directly to settle any dispute, claim, question, or disagreement and engage in good faith negotiations which shall be a condition to either party initiating a lawsuit or arbitration.
        `
      },
      {
        id: '15.2',
        title: 'Binding Arbitration',
        description: `If the parties do not reach an agreed upon solution within a period of 30 days from the time informal dispute resolution under the Initial Dispute Resolution provision begins, then either party may initiate binding arbitration as the sole means to resolve claims, subject to the terms set forth below. Specifically, all claims arising out of or relating to these Terms (including their formation, performance and breach), the parties' relationship with each other and/or your use of the Service shall be finally settled by binding arbitration administered by the American Arbitration Association in accordance with the provisions of its Commercial Arbitration Rules and the supplementary procedures for consumer related disputes of the American Arbitration Association (the "AAA"), excluding any rules or procedures governing or permitting class actions.

        The arbitrator, and not any federal, state or local court or agency, shall have exclusive authority to resolve all disputes arising out of or relating to the interpretation, applicability, enforceability or formation of these Terms, including, but not limited to any claim that all or any part of these Terms are void or voidable, or whether a claim is subject to arbitration. The arbitrator shall be empowered to grant whatever relief would be available in a court under law or in equity. The arbitrator's award shall be written, and binding on the parties and may be entered as a judgment in any court of competent jurisdiction.
        
        The parties understand that, absent this mandatory provision, they would have the right to sue in court and have a jury trial. They further understand that, in some instances, the costs of arbitration could exceed the costs of litigation and the right to discovery may be more limited in arbitration than in court.
        `
      },
      {
        id: '15.3',
        title: 'Location',
        description: `Binding arbitration shall take place in California. You agree to submit to the personal jurisdiction of any federal or state court in San Mateo County, California, in order to compel arbitration, to stay proceedings pending arbitration, or to confirm, modify, vacate or enter judgment on the award entered by the arbitrator.
        `
      },
      {
        id: '15.4',
        title: 'Class Action Waiver',
        description: `The parties further agree that any arbitration shall be conducted in their individual capacities only and not as a class action or other representative action, and the parties expressly waive their right to file a class action or seek relief on a class basis. YOU AND IICO TECK AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. If any court or arbitrator determines that the class action waiver set forth in this paragraph is void or unenforceable for any reason or that an arbitration can proceed on a class basis, then the arbitration provision set forth above shall be deemed null and void in its entirety and the parties shall be deemed to have not agreed to arbitrate disputes.
        `
      },
      {
        id: '15.5',
        title: 'Exception - Litigation of Intellectual Property and Small Claims Court Claims        ',
        description: `Notwithstanding the parties' decision to resolve all disputes through arbitration, either party may bring an action in state or federal court to protect its intellectual property rights ("intellectual property rights" means patents, copyrights, moral rights, trademarks, and trade secrets, but not privacy or publicity rights). Either party may also seek relief in a small claims court for disputes or claims within the scope of that court's jurisdiction.
        `
      },
      {
        id: '15.6',
        title: '30-Day Right to Opt Out',
        description: `You have the right to opt-out and not be bound by the arbitration and class action waiver provisions set forth above via email at cs@iicoteck.com. The notice must be sent within 30 days of your first use of the Service, whichever is later, otherwise you shall be bound to arbitrate disputes in accordance with the terms of those paragraphs. If you opt-out of these arbitration provisions, IICO TECK also will not be bound by them.`
      },
      {
        id: '15.7',
        title: 'Changes to this Section',
        description: `IICO TECK will provide 60-days' notice of any changes to this section. Changes will become effective on the 60th day, and will apply prospectively only to any claims arising after the 60th day.

        For any dispute not subject to arbitration you and IICO TECK agree to submit to the personal and exclusive jurisdiction of and venue in the federal and state courts located in California. You further agree to accept service of process by mail, and hereby waive any and all jurisdictional and venue defenses otherwise available.
        
        The Terms and the relationship between you and IICO TECK shall be governed by the laws of the State of California without regard to conflict of law provisions.
        `
      },

    ]
  },
  {
    id: 16,
    title: 'General Information',
    description: ` `,
    child: [

      {
        id: '16.1',
        title: 'Entire Agreement',
        description: `These Terms (and any additional terms, rules and conditions of participation that IICO TECK may post on the Site) constitute the entire agreement between you and IICO TECK with respect to the Service and supersedes any prior agreements, oral or written, between you and IICO TECK. In the event of a conflict between these Terms and the additional terms, rules and conditions of participation, the latter will prevail over the Terms to the extent of the conflict.
        `
      },
      {
        id: '16.2',
        title: 'Waiver and Severability of Terms',
        description: `The failure of IICO TECK to exercise or enforce any right or provision of the Terms shall not constitute a waiver of such right or provision. If any provision of the Terms is found by an arbitrator or court of competent jurisdiction to be invalid, the parties nevertheless agree that the arbitrator or court should endeavor to give effect to the parties' intentions as reflected in the provision, and the other provisions of the Terms remain in full force and effect.
        `
      },
      {
        id: '16.3',
        title: ' Governing Law',
        description: `This Agreement is governed by California law, without regard to conflicts of law principles. The exclusive jurisdiction and venue for disputes under or relating to the Agreement are the federal and state courts in San Francisco, California. You consent to jurisdiction and venue in such courts.
        `
      }, {
        id: '16.4',
        title: 'Statute of Limitations',
        description: `You agree that any claim or cause of action arising out of or related to the use of the Service or the Terms must be filed within one (1) year after such claim or cause of action arose, or another period as provided under statute or law, or be forever barred.
        `
      }, {
        id: '16.5',
        title: 'Entire Agreement',
        description: `This Agreement contains the complete and entire understanding between you and Us regarding your access to and use of the Services. The Agreement supersedes all prior agreements between you and Us regarding Your access to and use of the Services.
        `
      }, {
        id: '16.6',
        title: 'Section Titles',
        description: `The section titles in this Agreement are for convenience only and have no legal or contractual effect.
        `
      }, {
        id: '16.7',
        title: 'Communications',
        description: `Users with questions, complaints or claims with respect to the Site or Service may contact us using the relevant contact information set forth above.
        `
      },
    ]
  },
  {
    id: 17,
    title: 'In-App Transfers',
    description: `If you’re enabled to perform in-app transfers in our mobile applications, you also agree to the additional terms and conditions below.

    You agree not to offer any sales of OM or any derivatives of those units (e.g., “futures”) by you for other currencies prior to the launch of iiCO Network’s mainnet.
    You agree that you and only you are the exclusive holder of this account and the units of OM associated with your account and that you will not transfer your account to another individual, group, or organization.
    You agree not to engage in any illicit and / or illegal transactions using OM.
    Violation of any of these terms may result in the invalidation of your account and cancellation of your full balance, including any units of OM that have ever been transferred from your account. You agree that you assume full responsibility in the case of the invalidation of your account and in case of the cancellation of your OM balance and that IICO TECK, iiCO Network and its developers shall bear no liability if your account is invalidated, your balance is cancelled, or if any transferred units of OM have been cancelled.`
  }
]