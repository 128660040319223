/* eslint-disable no-unused-vars */
import React,{useState} from "react";
import { Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextDecrypt } from "../content/TextDecrypt";
import { FirstName, LastName } from "../../utils/getName";

import './About.css';

import profile from '../../assets/profile.jpg';
import IMGAI from '../../assets/recentprojects/pexels-tara-winstead-8386434.jpg';
import imgcrypto from '../../assets/recentprojects/pexels-leeloo-thefirst-7887812.jpg';
import imgsec from '../../assets/recentprojects/pexels-nicholas-githiri-1068349.jpg';

const useStyles = makeStyles((theme) => ({
  main: {
    maxWidth: '100vw',
    marginTop: '3em',
    marginBottom: "auto",
  },
}));

export const About2 = () => {
  const classes = useStyles();
  const greetings = "Hello there!";
  const aboutme = `IICO TECK is registered as a Private Limited Company.



  
  
  The company was established on 2023-05-04. Charter capital: GBP 15,000,000
  
  
  George Progosh(GP) is the Founder and CEO of IICO TECK. He will appear in public when IICO TECK enters the mainnet phase.
  
  
  
  At the start, IICO TECK was primarily funded by anonymous donors, and the project will continue to attract more funding. We are the founders and developers of the ii cryptocurrency revolution does not mean that we enjoy any privileges that are superior to the community. As a rule, neither members of our team nor investors are allocated the issued coins.
  
  
  
  IICO TECK is committed to using blockchain technology to establish an open internet platform and an ecosystem of Dapps (decentralized applications). We will gradually introduce applications of Blockchain to life, such as blockchain social networks, AI, Software, Security, and e-wallet. Our ultimate goal is ii coin to become an immortal coin and contributes to the value chain of the IICO TECK platform.`;
  const [projects, setProjects] = useState([
    { 
      id: 1,
      title: 'AI detected and security', 
      description: `For this project, we were able to design a system that uses AI to scan and recognize the visitor's face. First, the facial structure of family members or people who regularly visit the home can be scanned and stored. So, every time a visitor approaches the gate, the system can scan the face, and if it matches the existing facial structure stored in the database, the system can open the door and let the person through. , otherwise the gate may remain closed and the occupant may be notified that one is waiting outside.`,
      alter: 'Detected and Security',
      image: `${IMGAI}`,
    },
    { 
      id: 2,
      title: 'Software', 
      description: `Operating for more than a decade, IICO TECK is a privately held company specializing in quality software. The company provides global customers with a wide range of software development outsourcing services: Software Development, Software Testing, Migration and Migration, Production Support, IT Management Services and Online Design mandarin. In addition, IICOTECK has a great talent team with more than 100 well-trained and experienced engineers. With many projects completed over the years, IICO TECK is now one of the largest foreign software development companies.`,
      alter: 'Developing own software for large companies',
      image: `${imgsec}`,
    },
    { 
      id: 3,
      title: 'Cryptocurrency', 
      description: `And now we focus on cryptocurrencies like Bitcoin which are digital currencies that are not backed by real assets or physical securities. They are traded between agreeing parties without a broker and tracked on a digital ledger. is the golden time to grow right now.`,
      alter: 'AI-focused mining data and AI profits can help coins grow in the future',
      image: `${imgcrypto}`,
    },
   
  ]);
  return (
    <section id="about">
      <Container component="main" className={classes.main} maxWidth="md">
       {
        projects.map((e,i)=>{
          return  <div className="about">
          <div className="_img"
            style={{ 
              background: "url(" + e.image + ")",
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          >
          </div>
          <div className="_content_wrapper">
            <Typography component='h2' variant="h5">
              <TextDecrypt text={`${e.title}`} />
            </Typography>
            <p className="aboutme">
              {e.description}
            </p>
         
          </div>
        </div>
        })
       }
      </Container>
    </section>
  );
};
