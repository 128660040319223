/* eslint-disable no-unused-vars */
import React from "react";
import { Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextDecrypt } from "../content/TextDecrypt";
import { FirstName, LastName } from "../../utils/getName";

import './About.css';

import profile from '../../assets/profile.jpg';

const useStyles = makeStyles((theme) => ({
  main: {
    maxWidth: '100vw',
    marginTop: '3em',
    marginBottom: "auto",
  },
}));

export const About = () => {
  const classes = useStyles();
  const greetings = "Hello there!";
  const aboutme = `IICO TECK is registered as a Private Limited Company.



  
  
  The company was established on 2023-05-04
  
  
  George Progosh(GP) is the Founder and CEO of IICO TECK. He will appear in public when IICO TECK enters the mainnet phase.
  
  
  
  At the start, IICO TECK was primarily funded by anonymous donors, and the project will continue to attract more funding. We are the founders and developers of the ii cryptocurrency revolution does not mean that we enjoy any privileges that are superior to the community. As a rule, neither members of our team nor investors are allocated the issued coins.
  
  
  
  IICO TECK is committed to using blockchain technology to establish an open internet platform and an ecosystem of Dapps (decentralized applications). We will gradually introduce applications of Blockchain to life, such as blockchain social networks, AI, Software, Security, and e-wallet. Our ultimate goal is ii coin to become an immortal coin and contributes to the value chain of the IICO TECK platform.`;

  return (
    <section id="about">
      <Container component="main" className={classes.main} maxWidth="md">
        <div className="about">
          <div className="_img"
            style={{ 
              background: "url(" + profile + ")",
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          >
          </div>
          <div className="_content_wrapper">
            <Typography component='h2' variant="h5">
              <TextDecrypt text={`${greetings}`} />
            </Typography>
            <p className="aboutme">
              {aboutme}
            </p>
            <a href="#contact" className="contact-btn">
              {/* <i className="fas fa-terminal"></i> */}
              <Typography component='span'> Send me a message if you need invest for this project.</Typography>
            </a>
          </div>
        </div>
      </Container>
    </section>
  );
};
