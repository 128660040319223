import React from "react";
import { LogoLink } from "../components/logo/LogoLink";
import { Content } from "../components/content/Content";
import { Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DisplacementSphere from "../components/background/DisplacementSphere";
import { ThemeToggle } from "../components/theme/ThemeToggle";
import { Resume } from "../components/resume/Resume";
import { SocialIcons } from "../components/content/SocialIcons";
import { SpeedDials } from "../components/speedDial/SpeedDial";
import { SideNavbar } from "../components/nav/SideNavbar";
import { Typography } from "@material-ui/core";
import { Works } from "../components/works/Works";
import { About } from "../components/about/About";
import { About2 } from "../components/about2/About2";
import { Contact } from "../components/contact/Contact";
import background1 from "../assets/backgroung1.jpg";
const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
}));

export const Home = () => {
  const classes = useStyles();

  return (
    <>
      <div
        style={{
          // height:'100%',
          // width:300,
          paddingBottom: 100,
          background: "url(" + background1 + ")",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "scroll",
          position: "absolute",
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          zIndex: -1,
        }}
      >
        <div className={classes.root} id="home">
          <Content />
          <ThemeToggle />
          <SocialIcons />
        </div>
    
        <SideNavbar />
        {/* <Works /> */}
        <About2/>
        <About />
        <Contact />
   
       

        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            flexDirection: "column",
            textDecoration: "none",
            // backgroundColor:'red'
          }}
        >
          <Typography>© 2020 iiCO TECK LTD. | All rights reserved.</Typography>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <a
              href="/tos"
              // onClick={() => setActiveNav('/tos')}
              style={{ display: "flex", marginRight: 10 }}
            >
              <Typography>Terms of Service</Typography>
            </a>
            <p> </p>
            <a
              href="/pp"
              // onClick={() => setActiveNav('/pp')}
            >
              <Typography>Privacy Policy</Typography>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
