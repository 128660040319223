/* eslint-disable no-unused-vars */
import React from "react";
import { useState } from "react";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextDecrypt } from "../content/TextDecrypt";

import './Works.css';

// Import ../../assets/recentprojects/
import IMGAI from '../../assets/recentprojects/pexels-tara-winstead-8386434.jpg';
import imgcrypto from '../../assets/recentprojects/pexels-leeloo-thefirst-7887812.jpg';
import imgsec from '../../assets/recentprojects/pexels-nicholas-githiri-1068349.jpg';
import Startup from '../../assets/recentprojects/startup.png';
import Lacalle from '../../assets/recentprojects/lacalle.png';

const useStyles = makeStyles((theme) => ({
  main: {
    maxWidth: '100vw',
    marginTop: '3em',
    marginBottom: "auto",
  },
}));

export const Works = () => {
  const classes = useStyles();
  const [projects, setProjects] = useState([
    { 
      id: 1,
      title: 'AI detected and security', 
      description: `For this project, we were able to design a system that uses AI to scan and recognize the visitor's face. First, the facial structure of family members or people who regularly visit the home can be scanned and stored. So, every time a visitor approaches the gate, the system can scan the face, and if it matches the existing facial structure stored in the database, the system can open the door and let the person through. , otherwise the gate may remain closed and the occupant may be notified that one is waiting outside.`,
      alter: 'Detected and Security',
      image: `${IMGAI}`,
    },
    { 
      id: 2,
      title: 'Software', 
      description: `Operating for more than a decade, IICO TECK is a privately held company specializing in quality software. The company provides global customers with a wide range of software development outsourcing services: Software Development, Software Testing, Migration and Migration, Production Support, IT Management Services and Online Design mandarin. In addition, IICOTECK has a great talent team with more than 100 well-trained and experienced engineers. With many projects completed over the years, IICO TECK is now one of the largest foreign software development companies.`,
      alter: 'Developing own software for large companies',
      image: `${imgsec}`,
    },
    { 
      id: 3,
      title: 'Cryptocurrency', 
      description: `And now we focus on cryptocurrencies like Bitcoin which are digital currencies that are not backed by real assets or physical securities. They are traded between agreeing parties without a broker and tracked on a digital ledger. is the golden time to grow right now.`,
      alter: 'AI-focused mining data and AI profits can help coins grow in the future',
      image: `${imgcrypto}`,
    },
   
  ]);

  return (
    <section id="works">
      <Container component="main" className={classes.main} maxWidth="md">
        {projects.map((project) => (
          <div className="project" key={ project.id }>
            <div className="__img_wrapper" style={{height:'20%'}}>
              <img src={ project.image } alt={ project.alter }  />
            </div>
            <div className="__content_wrapper">
              <h3 className="title">
                <TextDecrypt text={ project.id + '. ' + project.title } />
              </h3>
              <p className="description">
                { project.description }
              </p>
            </div>
          </div>
        ))}
      </Container>
    </section>
  );
};
